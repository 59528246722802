
import Banner01 from "../../../public/index/428000396.avif"
import Banner03 from "../../../public/index/9f4e46f40d.avif"
import Banner04 from "../../../public/index/53000629.avif"
import Banner05 from "../../../public/index/407000014.avif"
import Banner06 from "../../../public/index/471000022.avif"
import Banner07 from "../../../public/index/284000681.avif"

import Link from "next/link";
import useMarketplaceChain from "../../../hooks/useMarketplaceChain";
import {Box, Flex,Text} from "../../primitives";
import {NextPage} from "next";


const Banner:NextPage = ()=> {
    const { routePrefix } = useMarketplaceChain()
    // @ts-ignore
    return (
        <>
            <Box css={{
                width: "100%",
                height: "100vh",
                background: `#FFFFFF`,
                backgroundSize: "cover",
                textAlign: "center",
                backgroundImage:`url(${Banner01.src})`,
                backgroundAttachment:"fixed",
                backgroundPosition:"center center",
                overflow:"hidden",
                paddingLeft:"$6"

            }}>
                <Flex  align="start" justify="end" direction="column" style={{
                    height:"calc(100vh - 20%)"
                }} >
                    <Box>
                        <Text css={{
                            color: "#FFFFFF",
                            fontSize: "3rem",
                            fontWeight:"bold",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "3rem"
                            }
                        }}>NFT Platform </Text>

                    </Box>
                    <Box>
                        <Text css={{
                            color:"#FFFFFF",
                            fontSize:"1.75rem",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "1.75rem"
                            }
                        }}>
                            Empowering creators, connecting collectors: the world's home for generative art
                        </Text>
                    </Box>

                    <Box css={{
                        marginTop:"50px"
                    }}>
                        <Link href={`/${routePrefix}/collections/trending`} style={{
                            backgroundColor: "black",
                            padding: "20px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>LIVE VIEW</span>
                        </Link>
                        <Link href={`/${routePrefix}/explore`} style={{
                            backgroundColor: "black",
                            padding: "20px",
                            marginLeft: "30px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>EXPLORE</span>
                        </Link>
                    </Box>
                </Flex>
            </Box>

            <Box css={{
                width: "100%",
                height: "100vh",
                background: `#FFFFFF`,
                backgroundSize: "cover",
                textAlign: "center",
                backgroundImage:`url(${Banner03.src})`,
                backgroundAttachment:"fixed",
                backgroundPosition:"center center",
                overflow:"hidden",
                paddingLeft:"$6"
            }}>
                <Flex  align="start" justify="end" direction="column" style={{
                    height:"calc(100vh - 20%)"
                }} >
                    <Box>
                        <Text css={{
                            color: "#FFFFFF",
                            fontSize: "3rem",
                            fontWeight:"bold",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "3rem"
                            }
                        }}>NFT Platform </Text>

                    </Box>
                    <Box>
                        <Text css={{
                            color:"#FFFFFF",
                            fontSize:"1.75rem",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "1.75rem"
                            }
                        }}>
                            Empowering creators, connecting collectors: the world's home for generative art
                        </Text>
                    </Box>

                    <Box css={{
                        marginTop:"50px"
                    }}>
                        <Link href={`/${routePrefix}/collections/trending`} style={{
                            backgroundColor: "black",
                            padding: "20px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>LIVE VIEW</span>
                        </Link>
                        <Link href={`/${routePrefix}/explore`} style={{
                            backgroundColor: "black",
                            padding: "20px",
                            marginLeft: "30px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>EXPLORE</span>
                        </Link>
                    </Box>
                </Flex>
            </Box>
            <Box css={{
                width: "100%",
                height: "100vh",
                background: `#FFFFFF`,
                backgroundSize: "cover",
                textAlign: "center",
                backgroundImage:`url(${Banner04.src})`,
                backgroundAttachment:"fixed",
                backgroundPosition:"center center",
                overflow:"hidden",
                paddingLeft:"$6"
            }}>
                <Flex  align="start" justify="end" direction="column" style={{
                    height:"calc(100vh - 20%)"
                }} >
                    <Box>
                        <Text css={{
                            color: "#FFFFFF",
                            fontSize: "3rem",
                            fontWeight:"bold",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "3rem"
                            }
                        }}>NFT Platform </Text>

                    </Box>
                    <Box>
                        <Text css={{
                            color:"#FFFFFF",
                            fontSize:"1.75rem",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "1.75rem"
                            }
                        }}>
                            Empowering creators, connecting collectors: the world's home for generative art
                        </Text>
                    </Box>

                    <Box css={{
                        marginTop:"50px"
                    }}>
                        <Link href={`/${routePrefix}/collections/trending`} style={{
                            backgroundColor: "black",
                            padding: "20px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>LIVE VIEW</span>
                        </Link>
                        <Link href={`/${routePrefix}/explore`} style={{
                            backgroundColor: "black",
                            padding: "20px",
                            marginLeft: "30px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>EXPLORE</span>
                        </Link>
                    </Box>
                </Flex>
            </Box>
            <Box css={{
                width: "100%",
                height: "100vh",
                background: `#FFFFFF`,
                backgroundSize: "cover",
                textAlign: "center",
                backgroundImage:`url(${Banner05.src})`,
                backgroundAttachment:"fixed",
                backgroundPosition:"center center",
                overflow:"hidden",
                paddingLeft:"$6"
            }}>
                <Flex  align="start" justify="end" direction="column" style={{
                    height:"calc(100vh - 20%)"
                }} >
                    <Box>
                        <Text css={{
                            color: "#FFFFFF",
                            fontSize: "3rem",
                            fontWeight:"bold",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "3rem"
                            }
                        }}>NFT Platform </Text>

                    </Box>
                    <Box>
                        <Text css={{
                            color:"#FFFFFF",
                            fontSize:"1.75rem",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "1.75rem"
                            }
                        }}>
                            Empowering creators, connecting collectors: the world's home for generative art
                        </Text>
                    </Box>

                    <Box css={{
                        marginTop:"50px"
                    }}>
                        <Link href={`/${routePrefix}/collections/trending`} style={{
                            backgroundColor: "black",
                            padding: "20px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>LIVE VIEW</span>
                        </Link>
                        <Link href={`/${routePrefix}/explore`} style={{
                            backgroundColor: "black",
                            padding: "20px",
                            marginLeft: "30px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>EXPLORE</span>
                        </Link>
                    </Box>
                </Flex>
            </Box>
            <Box css={{
                width: "100%",
                height: "100vh",
                background: `#FFFFFF`,
                backgroundSize: "cover",
                textAlign: "center",
                backgroundImage:`url(${Banner06.src})`,
                backgroundAttachment:"fixed",
                backgroundPosition:"center center",
                overflow:"hidden",
                paddingLeft:"$6"
            }}>

                <Flex  align="start" justify="end" direction="column" style={{
                    height:"calc(100vh - 20%)"
                }} >
                    <Box>
                        <Text css={{
                            color: "#FFFFFF",
                            fontSize: "3rem",
                            fontWeight:"bold",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "3rem"
                            }
                        }}>NFT Platform </Text>

                    </Box>
                    <Box>
                        <Text css={{
                            color:"#FFFFFF",
                            fontSize:"1.75rem",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "1.75rem"
                            }
                        }}>
                            Empowering creators, connecting collectors: the world's home for generative art
                        </Text>
                    </Box>

                    <Box css={{
                        marginTop:"50px"
                    }}>
                        <Link href={`/${routePrefix}/collections/trending`} style={{
                            backgroundColor: "black",
                            padding: "20px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>LIVE VIEW</span>
                        </Link>
                        <Link href={`/${routePrefix}/explore`} style={{
                            backgroundColor: "black",
                            padding: "20px",
                            marginLeft: "30px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>EXPLORE</span>
                        </Link>
                    </Box>
                </Flex>
            </Box>
            <Box css={{
                width: "100%",
                height: "100vh",
                background: `#FFFFFF`,
                backgroundSize: "cover",
                textAlign: "center",
                backgroundImage:`url(${Banner07.src})`,
                backgroundAttachment:"fixed",
                backgroundPosition:"center center",
                overflow:"hidden",
                paddingLeft:"$6"
            }}>
                <Flex  align="start" justify="end" direction="column" style={{
                    height:"calc(100vh - 20%)"
                }} >
                    <Box>
                        <Text css={{
                            color: "#FFFFFF",
                            fontSize: "3rem",
                            fontWeight:"bold",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "3rem"
                            }
                        }}>NFT Platform </Text>

                    </Box>
                    <Box>
                        <Text css={{
                            color:"#FFFFFF",
                            fontSize:"1.75rem",
                            fontFamily: "Playfair Display",
                            "@md": {
                                fontSize: "1.75rem"
                            }
                        }}>
                            Empowering creators, connecting collectors: the world's home for generative art
                        </Text>
                    </Box>

                    <Box css={{
                        marginTop:"50px"
                    }}>
                        <Link href={`/${routePrefix}/collections/trending`} style={{
                            backgroundColor: "black",
                            padding: "20px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>LIVE VIEW</span>
                        </Link>
                        <Link href={`/${routePrefix}/explore`} style={{
                            backgroundColor: "black",
                            padding: "20px",
                            marginLeft: "30px"
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>EXPLORE</span>
                        </Link>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}
export default Banner