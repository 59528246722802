
import useMarketplaceChain from "hooks/useMarketplaceChain";
import {NextPage } from 'next'
import Banner from "./components/Banner";
import styled from 'styled-components'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'

import Link from "next/link";
import { Button} from "../primitives";
import {useRef} from "react";
const SlideWrap = styled.div`
  //@apply mt-7;
`
const LandWrap = styled.div`
    margin-left: auto;
    margin-right:auto;
    padding_left:0.75rem;
    padding-right:0.75rem;
    max-width:1590px;
    color:#D7D8D8;
    display:grid;
    margin-top:116px;
    @media (max-width: 600px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media (min-width: 900px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    
    @media (min-width: 1820px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        
    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`

const SalesWrap = styled.div`
    @media (max-width: 600px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media (min-width: 900px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media (min-width: 1820px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));

    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`
const  H2 = styled.h2`
    font-size: 50px;
    font-weight: 800;
    line-height: 1.2;
    color: #D7D8D8;
    margin: 180px auto 66px;
    padding_left: 0.75rem;
    padding-right: 0.75rem;
    max-width: 1590px;

`
const H2O = styled.h2`
    margin-bottom: 66px;
    font-size: 50px;
    font-weight: 800;
    line-height: 1.2;
    color: #D7D8D8;
`
const BlockItem = styled.div`
   height: 60px;
`
const NFTBanner: NextPage = () => {
    const swiperRef = useRef(null)
    const { routePrefix } = useMarketplaceChain()




    return (
        <div style={{
            backgroundColor:"black"
        }}>
            <div id='bannerWrap'>
                <Banner/>
            </div>
        </div>
    )
}


export default NFTBanner